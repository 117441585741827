.root {
  position: sticky;
  top: 0;
  z-index: 1;
  height: auto;
  padding: 10px 24px;
  background-color: white;
  box-shadow: 0 1px 4px 0px #a3a3a3;

  .orderId {
    color: #989595;
  }

  .amount {
    font-weight: bold;
    color: black;
  }

  .amountSkeleton :global(.ant-skeleton-paragraph) {
    margin-bottom: 0;
  }

  .icon {
    height: 36px;
    width: 36px;
    border-radius: 18px;
    color: white;

    span {
      font-size: 24px;
      margin: 6px;
    }
  }

  .statusCol {
    height: 44px;
    line-height: 44px;
    display: inline-flex;
    margin-left: 24px;

    .statusColSkeleton :global(.ant-skeleton-paragraph) {
      margin-bottom: 0;
    }
  }
}

.radio-grey {
  span {
    border-color: grey !important;
    ::after {
      background-color: grey !important;
    }
  }
}
.radio-red {
  span {
    border-color: red !important;
    ::after {
      background-color: red !important;
    }
  }
}
.radio-orange {
  span {
    border-color: orange !important;
    ::after {
      background-color: orange !important;
    }
  }
}
.radio-green {
  span {
    border-color: green !important;
    ::after {
      background-color: green !important;
    }
  }
}
.radio-saddlebrown {
  span {
    border-color: saddlebrown !important;
    ::after {
      background-color: saddlebrown !important;
    }
  }
}
.radio-black {
  span {
    border-color: black !important;
    ::after {
      background-color: black !important;
    }
  }
}
