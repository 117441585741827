.loading {
  margin: 165px;
}

.subtitle {
  font-size: 12px;
  margin-top: 4px;
}

.upload {
  cursor: pointer;
  :global(.ant-upload-select) {
    position: relative;
  }
}

.cameraIcon {
  position: absolute;
  bottom: 24px;
  right: 8px;
  font-size: 14px;
  padding: 4px;
  background-color: white;
  border: 1px solid var(--primaryColor);
  border-radius: 12px;
  color: var(--primaryColor) !important;
}

.avatarImg {
  border-radius: 40px;
  width: 80px;
  height: 80px;
  margin: 24px 8px 24px 24px;
  box-shadow: 0 3px 5px 1px #eaeaea;
}

.avatarInitials {
  border-radius: 40px;
  width: 80px;
  height: 80px;
  background-color: var(--primaryColor);
  color: var(--primaryColorContrast);
  line-height: 80px;
  text-align: center;
  font-size: 32px;
  margin: 24px 8px 24px 24px;
  box-shadow: 0 3px 5px 1px #eaeaea;
}

.userForm {
  margin-bottom: 24px;
  padding: 6px 20px;
  :global(.ant-form-item) {
    margin-bottom: 0;
    :global(.ant-form-item-label) {
      padding: 4px 0;
      label {
        font-size: 12px;
        color: grey;
      }
    }
  }
}

.userButtons {
  margin-bottom: 24px;
  padding: 6px 20px;
  button {
    margin: 4px 0;
    border-width: 2px;
  }
}
