.root {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;

  &.shown {
    opacity: 1;
    height: auto;
  }
}
