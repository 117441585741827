.rowList {
  &:global(.ant-row) {
    margin: 24px 0;
  }
}

.createButton {
  margin: 8px 0;
  height: 72px;
  &:global(.ant-btn) {
    text-align: left;
    background-color: var(--secondaryColor) !important;
    color: var(--secondaryColorContrast) !important;
  }
}

.listItem {
  &:global(.ant-list-item) {
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 #eaeaea;
    margin-bottom: 8px;
    padding: 12px 8px;
    &.active {
      background-color: var(--primaryColor);
      :global(.ant-list-item-meta-title) {
        color: var(--primaryColorContrast);
      }
      :global(.ant-list-item-meta-description) {
        color: var(--primaryColorContrast);
      }
      :global(.ant-tag) {
        background-color: var(--primaryColor);
        color: var(--primaryColorContrast);
      }
      :global(.anticon) {
        color: var(--primaryColorContrast) !important;
      }
    }
    :global(.ant-avatar) {
      background-color: var(--primaryColor);
    }
    &:focus {
      box-shadow: 0 3px 5px 1px #eaeaea;
    }
  }
}