.root {
  .menuBottom {
    position: sticky;
    bottom: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .avatar {
    line-height: 32px !important;
    background-color: var(--primaryColor);
  }

  .collapsed {
    margin: 0 -8px;
  }

  .itemGroup {
    margin: 32px 0;
  }

  :global(.ant-layout-sider-children) {
    overflow-x: hidden;
  }

  :global(.ant-ribbon-wrapper) {
    position: initial;
    > :global(.ant-ribbon) {
      z-index: 100;
      top: unset;
      bottom: 8px;
      font-size: 12px;
      text-transform: capitalize;
    }
  }
}

.subMenuItem {
  background-color: var(--secondaryColor) !important;
}

.companyName {
  background-color: var(--primaryColor);
  color: var(--primaryColorContrast);
  border-radius: 4px;
  margin: 8px;
  padding: 4px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 22px;
  height: 30px;
}
.companyLogo {
  width: 100%;
}
.companyIcon {
  margin: 0;
  width: 100%;
  display: initial;
}

.menu {
  padding-bottom: 40px;
  .exportBadge {
    position: static;
  }
  .exportIcon {
    position: static;
    color: var(--secondaryColorContrast);
  }
  :global(.ant-menu-sub > .ant-menu-item) {
    padding-left: 24px !important;
  }
  &:global(.ant-menu.ant-menu-inline-collapsed) {
    :global(.ant-menu-item),
      :global(.ant-menu-submenu-title) {
      padding: 0 calc(50% - 12px) !important;
      font-size: 22px;
      :global(.ant-menu-title-content) {
        font-size: 14px;
      }
      :global(.anticon.ant-menu-item-icon) {
        font-size: 22px !important;
      }
    }
    :global(.ant-menu-item-group-title) {
      border-bottom: 1px solid;
      margin: 16px 30px;
      padding: 0;
      height: 0;
    }
  }
  :global(.ant-menu-item) {
    :global(.anticon.ant-menu-item-icon) {
      font-size: 20px;
    }
  }
}
