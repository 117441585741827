.notificationUrlContainer {
  :global(label) {
    width: 100%;
  }

  .notificationUrl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .copyIcon {
      transition: transform 0.2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}

.drawerForm {
  margin-bottom: 24px;
  :global(.ant-form-item) {
    margin-bottom: 8px;
    :global(.ant-form-item-label) {
      padding: 4px;
      label {
        font-size: 12px;
        color: grey;
      }
    }
  }
}