.dropArea {
  border: 1px dashed #d9d9d9;
  height: 32px;
  color: #d9d9d9;
  text-align: center;
  padding: 4px;

  &.dragOver {
    border-color: var(--primaryColor);
    color: var(--primaryColor);
  }
}
