.root {
  right: 0;
  top: 0;
  bottom: auto;
  max-height: 100vh;
  position: fixed;
  z-index: 999;
  margin-right: 12px;
  width: 400px;
  overflow-y: auto;
  padding: 24px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.closeAll {
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 12px 12px 12px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid rgba(0,0,0,.06);
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;

  .messageCount {
    :global(.ant-badge-count) {
      background: var(--primaryColor);
      color: var(--primaryColorContrast);
    }
  }
}

:global(.ant-notification-notice) {
  border-radius: 8px;
}

.message {

  :global(.ant-notification-notice-btn) {
    width: 100%;

  }
}