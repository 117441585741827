
.navContainer {
  display: flex;
  gap: 8px;
  margin: 48px 24px;
  justify-content: center;

  .navBtn {
    width: 160px;
    border: 1px var(--primaryColor) solid;
    border-radius: 2px;
    text-align: center;
    height: 100%;
    padding: 8px;
    font-size: 14px;
    display: table;
    > div {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .activeNavBtn {
    background-color: var(--primaryColor);
    color: var(--primaryColorContrast);
  }
}