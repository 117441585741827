.subtitle {
  font-size: 12px;
}

.userForm {
  //margin-bottom: 24px;
  //padding: 6px 20px;
  //:global(.ant-form-item) {
  //  margin-bottom: 8px;
  //  :global(.ant-form-item-label) {
  //    padding: 0;
  //  //  label {
  //  //    font-size: 11px;
  //  //    color: grey;
  //  //  }
  //  }
  //}
}
