.menu {
  :global(.ant-dropdown-menu) {
    padding-top: 0;
    max-height: 400px;
    overflow: auto;
  }

  :global(.ant-dropdown-menu > li:first-child) {
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 10;
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  :global(.ant-dropdown-menu-item-selected) {
    &:hover {
      background-color: var(--ant-primary-1);
    }
  }
}

.dropdown {
  &:global(.ant-btn.ant-dropdown-trigger) {
    color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
    &:global(.ant-dropdown-open) {
      background-color: var(--primaryColor);
      color: var(--primaryColorContrast);
    }
    &:global(:not(.ant-btn-circle)) {
      height: 38px;
      border-radius: 6px;
    }
  }
}
