.root {
  max-width: 448px;
  margin: auto;
  padding: 12px 24px;
}

.dot {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  color: white;

  .dotIcon {
    font-size: 24px;
    margin: 6px;
  }
}

.itemContent {
  .itemTitle {
    font-size: 12px;
    padding: 4px 0;
    margin-bottom: 4px;
  }

  .itemCard {
    box-shadow: 1px 0px 4px #a3a3a3;
    padding: 6px;
    background-color: white;

    .itemCardTitle {
      font-size: 14px;
    }

    .itemCardInfo {
      font-size: 13px;
      color: #989595;
    }
  }
}

.timeline {
  margin: 0 0 60px 30px;
}

.block {
  position: relative;
  margin-bottom: 40px;
  display: flex;

  .blockDot {
    display: inline-block;
    flex-shrink: 0;
    height: 68px;
    width: 68px;
    border-radius: 34px;
    border-style: double;
    border-width: 10px;
    color: white;
    background-color: grey;
    text-align: center;

    span {
      line-height: 46px;
      font-size: 24px;
    }
  }

  .blockCard {
    display: inline-block;
    width: -webkit-fill-available;
    margin-left: 8px;
    padding: 6px;
    box-shadow: 1px 0px 4px #a3a3a3;
    background-color: white;
    border: grey 1px solid;

    .blockCardTitle {
      font-size: 13px;
      // font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .blockCardTitleText {
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .blockCardInfo {
      font-size: 13px;
      color: #989595;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
