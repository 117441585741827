.root {
  display: flex;
  flex-direction: column;
}

.formButtons {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;

  * {
    text-transform: uppercase;
  }
}

.bottomButtons {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;

  * {
    text-transform: uppercase;
  }
}
