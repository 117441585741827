.root {
  display: inline-flex;
  align-items: center;
  color: #17171780;
  overflow: hidden;

  .text {
    margin-right: 4px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    z-index: 1;
    max-width: 0;
    transition: max-width 0.2s linear;
    overflow: hidden;
    line-height: 40px;
    height: 40px;
  }

  .icon {
    font-size: 20px;
    background-color: white;
    z-index: 2;

    &:hover {
      color: #171717;
    }
  }

  &:hover {
    cursor: pointer;

    .text {
      cursor: default;
      color: #17171780;
      display: inline-block;
      max-width: 140px;
    }
  }
}
