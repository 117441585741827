.root {
  position: relative;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-left: 8px solid var(--primaryColor);
  background-color: #f8f8f8;
  min-height: 48px;
  overflow: hidden;
  &.withBackButton {
    padding-left: 0;
  }
  .backButton {
    float: left;
    margin: 6px;
  }
  .title {
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
  }
  .description {
    font-size: 11px;
    text-overflow: ellipsis;
  }
}
