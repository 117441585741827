.subHeader {
  margin-bottom: 8px;
  display: inline-flex;
  gap: 8px;
}

.addViewBtn {
  height: 38px;
  border-radius: 6px;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  margin-top: 12px;
}
