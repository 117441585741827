.root {
  height: 100vh;
  background-color: var(--secondaryColor) !important;
  color: var(--primaryColor) !important;
  .header {
    height: calc(50% - 160px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    img {
      margin: 0 auto;
      max-height: 80px;
      max-width: 200px;
    }
    .env {
      font-size: 18px;
      position: absolute;
      right: 24px;
      top: 24px;
      background-color: var(--primaryColor);
      color: var(--primaryColorContrast);
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
  .footer {
    height: calc(50% - 160px);
  }
}

.container {
  margin: 0 auto;
  width: 340px;
}

.block {
  background-color: whitesmoke;
  border-radius: 2px;
  width: 340px;
  min-height: 320px;
  padding: 12px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  :global(h4.ant-typography) {
    color: var(--primaryColor) !important;
  }

  .loginContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.description {
  font-size: 11px;
  color: #6d7172 !important;
  text-align: justify;
}

.form {
  .placeHolder {
    height: 56px;
  }

  .error {
    font-size: 11px;
  }
  .forgotPassword {
    position: absolute;
    font-size: 11px;
    margin-top: -24px;
    right: 24px;
  }
  :global(.ant-form-item-explain) {
    font-size: 11px;
    text-align: left;
  }
}

.submitBtn {
  min-width: 160px;
  margin-top: 24px;

  &.sso {
    margin-top: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin: 12px auto;
  .btn {
    width: 160px;
    //background-color: grey !important;
    //color: white !important;
    //font-weight: bold;
    margin: 12px;
  }
}

.backLink {
  align-self: flex-start;
}
