.root {
  display: flex;
  flex-grow: 1;

  .select {
    text-align: start;
    min-width: 280px;
    margin: auto;
  }
}
