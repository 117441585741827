.table {
  margin-left: 16px;

  &.richDisplay {
    tr > td {
      padding: 6px 8px;
    }
  }

  :global(th.ant-table-cell) {
    font-family: 'Montserrat-Regular', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
    height: 39px;
    background-color: #f0f2f5;
    font-size: 12px;
  }
  :global(tr.ant-table-row) {
    cursor: pointer;
    :global(td.ant-table-cell) {
      height: 40px;
      border-bottom: none;
    }
    :global(td.ant-table-cell.ant-table-cell-fix-left .ant-table-cell-content) {
      display: inline-flex;
    }
    &:nth-child(2n+1){
      background: #fff;
      :global(td.ant-table-cell.ant-table-cell-fix-left) {
        background-color: #fff;
      }
    }
    &:nth-child(2n+1){
      background: #fff;
      :global(td.ant-table-cell.ant-table-cell-fix-left) {
        background-color: #fff;
      }
    }
    &:nth-child(2n){
      background: #fafafa;
      :global(td.ant-table-cell.ant-table-cell-fix-left) {
        background-color: #fafafa;
      }
    }
    // child row
    &:global(.ant-table-row-level-1) {
      background-color: lightgrey;
      :global(td.ant-table-cell.ant-table-cell-fix-left) {
        background-color: lightgrey;
      }
    }
  }

  // first child row -> add top shadows
  :global(tr.ant-table-row.ant-table-row-level-0) + :global(tr.ant-table-row.ant-table-row-level-1) > td {
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.24);
  }
  // last child row -> add bottom shadows
  :global(tr.ant-table-row.ant-table-row-level-1) + :global(tr.ant-table-row.ant-table-row-level-0) {
    box-shadow: 0 -7px 9px -7px rgba(0, 0, 0, 0.24);
    :global(td.ant-table-cell.ant-table-cell-fix-left) {
      box-shadow: 0 -7px 9px -7px rgba(0, 0, 0, 0.24);
    }
  }

  :global(td.ant-table-column-sort) {
    background: initial;
  }
  :global(.ant-table-cell.ant-table-cell-row-hover) {
    background-color: var(--ant-primary-color-active-deprecated-d-02) !important;
    :global(td.ant-table-cell.ant-table-cell-fix-left) {
      background-color: var(--ant-primary-color-active-deprecated-d-02) !important;
    }
  }

  .empty {
    display: table-cell;
    width: inherit;
    vertical-align: middle;
  }
}

.expandButton {
  width: 26px !important;
  margin-right: 8px !important;
  border-radius: 6px !important;
}

.layoutHeader {
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.24) 0 7px 4px -7px;
  padding: 0 16px;
  height: auto;
}

.layoutContent {
  position: relative;
  margin: 0;
}

.layoutFooter {
  background-color: white;
  padding: 16px;
  z-index: 40;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0 4px;
}

.pagination {
  padding: 4px 0;
  :global(.ant-pagination-item-active) {
    background-color: var(--primaryColor);
    a {
      color: white !important;
    }
  }
}

.tagCell {
  padding: 3px 8px;
  border-radius: 4px;
  position: relative;
  > :global(.anticon) {
    font-size: 14px;
    vertical-align: sub;
  }
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
    border-radius: 4px;
  }
}
