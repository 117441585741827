.vLineContainer {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1;
  width: 1px;
  background: var(--ant-primary-color);

  &.active {
    width: 2px;
  }

  &.edit {
    background: repeating-linear-gradient(rgba(23, 23, 23, 0.5), rgba(23, 23, 23, 0.5) 5px, transparent 5px, transparent 10px);

    &.active {
      background: repeating-linear-gradient(var(--primaryColor), var(--primaryColor) 5px, transparent 5px, transparent 10px);

      .addIcon {
        color: var(--primaryColor);
      }
    }
  }

  .addIcon {
    position: absolute;
    bottom: -12px;
    left: -12px;
    font-size: 24px;
    color: rgba(23, 23, 23, .5);
    background-color: white;
    box-sizing: content-box;

    &.active {
      color: var(--ant-primary-color);
      transform: scale(1.1);
    }
  }
}