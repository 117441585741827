.label {
  font-size: 9px;
  color: #b5b5b5;
  margin-bottom: 2px;
}

.slotContainer {
  position: absolute;
  left: 8px;
  color: #b5b5b5;
  font-size: 9px;

  > div {
    height: 40px;
  }

  .slotWithSeparator {
    height: 52px;
  }
}

.separator {
  border-top: dashed #b5b5b5 1px;
  margin-bottom: 8px;
  position: relative;
  overflow: visible;
  color: #b5b5b5;
  margin-left: 16px;
  > span {
    position: absolute;
    top: -4px;
    font-size: 5px;
    left: -13px;
  }
}
