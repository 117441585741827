.root {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(23, 23, 23, .5);

  &.edit {
    justify-content: space-around;
  }

  .button {
    width: 200px;
    text-transform: uppercase;
    margin: 4px;
  }

  .versionSelector {
    margin: 8px;
    min-width: 300px;

    &.aligner {
      visibility: hidden;
    }
  }
}