.root {
  height: 100%;
  width: 100%;
}

.container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error {
  background-color: white;
  height: 100%;
  padding: 4px 12px;
}
