@import '../../../MatcherTable/MatcherTable.module';

.radio {
  text-transform: uppercase;
  :first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  :last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.loadingRow {
  text-align: center;

  &:not(.isLoading) {
    display: none;
  }
}

.table {
  :global(.ant-table-expanded-row-fixed) {
    padding: 4px;
  }
  :global(tr.ant-table-row.ant-table-row-level-1 td.ant-table-cell.ant-table-cell-fix-left) {
    border: none;
  }
  :global(tr.ant-table-row) {
    cursor: auto;
  }
}

//:global(table tr.ant-table-expanded-row.ant-table-expanded-row-level-1) {
//  display: none;
//}
