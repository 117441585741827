.root {
  width: 100%;
  background-color: whitesmoke;
}

.body {
  padding-top: 24px;
}

.layoutHeader {
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.24) 0 7px 4px -7px;
  padding: 0 16px;
  height: auto;
}

.actionButton {
  border: 1px solid var(--ant-primary-color);
  color: var(--ant-primary-color);
  border-radius: 6px;
}

.primaryActionButton {
  border-radius: 6px;
}

.ellipsis {
  display: flex;
  min-width: 0;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
