.root {
  height: 64px;
  transition: all 0.5s;
  position: sticky;
  top: 0;
  background-color: var(--secondaryColor);
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  > img {
    object-fit: contain;
    max-height: 64px;
    max-width: 200px;
  }

  .nameContainer {
    color: var(--secondaryColorContrast);
    margin: 0 4px;
    text-overflow: ellipsis;
    text-align: center;
    max-height: 64px;
    max-width: 185px;
    white-space: nowrap;
    overflow: hidden;

    &.collapsed {
      max-width: 64px;
    }
  }
}
