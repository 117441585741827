.layout {
  transition: all 0.2s;
}

.content {
  margin-top: 64px;
  background-color: white;
  position: relative;

  &.scroll {
    overflow: auto;
  }
}
