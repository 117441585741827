.content {
  background-color: white;

  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .conditionsButton {
    text-transform: uppercase;
  }

  .grid {
    margin: 80px 30px;
    max-width: 100%;
    display: grid;
    grid-gap: 24px 0;

    .splitter {
      position: relative;
    }

    .branch {
      position: relative;
    }


    .splitter,
    .branch,
    .contract,
    .contractSelect,
    .mAccount {
      line-height: 18px;
      height: 58px;
      z-index: 999;
      background-color: #F5F5F5;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
    }

    .mAccount {
      grid-area: 1 / 1 / -1 / 1;
      text-align: center;
      display: table;
      background-color: white;

      .mAccountName {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}