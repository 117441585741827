.root {
  overflow-x: hidden;

  .header {
    line-height: 91px;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;

    .closeIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 20px;
    }
  }
}
