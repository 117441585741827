.subHeader {
  margin-bottom: 8px;
  display: inline-flex;
  gap: 8px;
}

.description {
  margin-bottom: 8px;
  font-size: 12px;
  color: grey;
  b {
    color: black;
  }
}