.switch {
  margin-right: 8px;
  &:global(.ant-switch-checked) {
    background-color: var(--positive) !important;
  }
}

.isLoading {
  filter: blur(2px);
}
