.actionButton {
  margin: 6px 0;

  :global(.ant-btn) {
    width: 100%;
  }
}

.loadingCsv {
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyList {
  text-align: center;
}

.separatorLabel {
  margin-bottom: 12px;
}