.root {
  transition: opacity 0.5s, transform 0.5s;
  transform: translate(0%);
  opacity: 1;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 25px 8px 24px;

  &.isColumnActive {
  }

  &.oob {
    display: none;
  }

  //&:hover {
  //  cursor: pointer;
  //  color: white;
  //  background-color: var(--primaryColor);
  //  font-weight: bold;
  //}

  &.isMovingLeft {
    transition: all 0s !important;
    transform: translate(-100%);
  }

  &.isMovingRight {
    transition: all 0s !important;
    transform: translate(100%);
  }

  &.isAppearing {
    opacity: 0;
    z-index: 1;
  }

  &:not(.isColumnActive) {
    opacity: 0.32;
  }

  .button {
    min-width: 70%;
    height: 44px;
    border-radius: 10px;
    color: var(--primaryColorContrast);
    background-color: var(--primaryColor);
    font-weight: bold;
  }
}
