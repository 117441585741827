.modal {
  font-family: 'Montserrat-Medium', 'Montserrat-SemiBold', 'Montserrat-Regular', 'Roboto', 'Helvetica Neue', 'Arial',
    'sans-serif';

  :global(.ant-modal-content) {
    border-radius: 8px;
    overflow: hidden;
  }
  :global(.ant-modal-header) {
    border-bottom: none;
    font-weight: bold;
  }
  :global(.ant-modal-body) {
    border-bottom: none;
    margin: 16px;
    border-radius: 8px;
    background-color: #f5f4f4;
  }
  :global(.ant-modal-footer) {
    border-top: none;
    padding: 16px;
  }
  :global(.ant-form-item-label > label) {
    color: #757575;
  }

  .footer {
    display: flex;
    gap: 8px;
    > button {
      width: 100%;
      border-radius: 4px;
    }
  }
}
