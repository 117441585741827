.addableItem {
  position: relative;
  background-color: white;
  box-shadow: 1px 1px 4px #d0d0d0;
  border-radius: 2px;
  margin: 4px;
  transition:
    padding 0.3s,
    margin 0.3s,
    width 0.3s,
    height 0.3s;
  color: #868686;
  &:hover {
    box-shadow: 1px 1px 4px #969696;
    color: #000000;
  }
  :global(.ant-row) {
    padding: 4px;
    * {
      color: inherit;
    }
    :global(.anticon) {
      font-size: 24px;
    }
  }
  .content {
    height: 0;
    opacity: 0;
    transition: inherit;
    > div {
      padding: 8px;
      :global(.ant-typography) {
        font-size: 12px;
      }
    }
  }
  &.open > .content {
    height: 160px;
    opacity: 1;
  }
}

.setUpItem {
  position: relative;
  background-color: white;
  box-shadow: 1px 1px 4px #d0d0d0;
  border-radius: 2px;
  margin: 0 32px 8px 0;
  transition:
    padding 0.3s,
    margin 0.3s,
    width 0.3s,
    max-height 0.3s;
  color: #868686;

  &:hover {
    box-shadow: 1px 1px 4px #969696;
    color: #000000;
  }

  &.open {
    margin: 0 0 8px 0;
    padding-right: 32px;
  }

  :global(.ant-row) {
    padding: 4px;
    * {
      color: inherit;
    }
    :global(.anticon) {
      font-size: 24px;
      margin-top: 3px;
    }
    .labelInput {
      padding-left: 4px;
      &:focus,
      &:hover {
        background-color: whitesmoke;
      }
    }
  }
  &.open > :global(.ant-row) {
    color: var(--primaryColor);
  }

  .content {
    max-height: 0;
    opacity: 0;
    transition: inherit;
    > div {
      padding: 8px;
      :global(.ant-typography) {
        font-size: 12px;
      }
    }
  }
  &.open > .content {
    max-height: 280px;
    opacity: 1;
  }
  &.open.large > .content {
    max-height: 440px;
  }

  .removeButton {
    position: absolute;
    right: -32px;
    top: 8px;
    opacity: 1;
    color: inherit;
  }
  &.open > .removeButton {
    opacity: 0;
    display: none;
  }
}

.list {
  :global(.ant-list-item) {
    position: relative;
    background-color: white;
    box-shadow: 1px 1px 4px #d0d0d0;
    border-radius: 2px;
    padding: 8px 12px;
    margin-bottom: 8px;
    transition: 0.3s;
    //color: #868686;
    cursor: pointer;
    height: 48px;
    .date {
      display: inline-block;
      opacity: 1;
      color: lightgrey;
    }
    .editBtn {
      display: none;
      opacity: 0;
      color: #7c7c7c;
    }
    &:hover {
      box-shadow: 1px 1px 4px #969696;
      > .date {
        display: none;
        opacity: 0;
      }
      > .editBtn {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
