.root {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #20272b;
  max-height: 80vh;
  overflow-y: auto;

  .errorIcon {
    margin: 16px;
    color: var(--negative);
    font-size: 70px;
  }

  .header {
    padding: 16px;
    font-weight: 600;
  }

  .content {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    align-self: stretch;
    padding: 20px;
  }

  .buttonsContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .modalButton {
      border: 1px solid #707070;
      font-family: Montserrat-SemiBold;
      transition: 0.5s;
      border-radius: 3px;
      font-size: 20px;
      padding: 0 18px;
      height: 52px;
      line-height: 0;
      margin: 0 4px;
    }
  }
}

.contractSelector {
  width: 250px;
  margin: auto;
  display: block;
}

.finishButtonsContainer {
  display: flex;
  justify-content: flex-end;
}

.newContractSelector {
  display: flex;
  align-items: center;
  margin: 20px;
  justify-content: space-between;
}

.paymentMethodLabel {
  flex: 1;
}

.defaultProvider {
  //font-weight: bold;
  color: var(--primaryColor);
}

.loader {
  margin: auto;
  display: block;
}