.paymentNetwork.noMerchantAccount:hover {
  cursor: not-allowed;
}

.paymentNetwork.noMerchantAccount {
  opacity: 0.5;
  pointer-events: none;
}

.noMerchantMessage {
  text-align: center;
  color: #B40505;
  padding-top: 0.5rem;
}
