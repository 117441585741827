.root {
  padding: 12px 12px 12px 18px;
  background-color: white;
  box-shadow: 0px 4px 12px #1717174D;
  border-radius: 8px;
  transition: transform .2s linear;
  cursor: pointer;

  &.disappearing {
    transform: scaleY(0);
  }

  .header {
    display: flex;
    align-items: center;
  }

  .content {
    color: #171717;
    font-family: Montserrat-Regular;
    height: 44px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 16px 0;
    display: -webkit-box;
    overflow: hidden;

    b {
      font-family: Montserrat-SemiBold;
    }
  }
}

.titleIcon {
  font-size: 18px;

  &.error {
    color: red;
  }

  &.success {
    color: green;
  }
}