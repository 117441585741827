.filterButtons {
  display: flex;
  gap: 20px;
  margin: 12px 0 12px 0;
  > :global(.ant-btn) {
    height: 38px;
    border-radius: 6px;
    color: #868686;
    border: 1px solid #868686;
  }
}
