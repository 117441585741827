.root {
  &.isLoading {
    filter: blur(2px);
    cursor: not-allowed;
    pointer-events: none;
  }

  .badge {
    margin: 8px;

    &.active {
      :global(.ant-badge-count) {
        background: var(--positive);
        color: white;
      }
    }

    &.available {
      :global(.ant-badge-count) {
        background: grey;
      }
    }
  }

  .draggable {
    &:hover {
      cursor: pointer;
    }
  }

  .configNeeded {
    opacity: .5;
    pointer-events: none;
  }

  .activePaymentMethod {
    &:hover {
      cursor: pointer;
    }
  }
}
