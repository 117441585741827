.hLineContainer {
  position: relative;

  .hLine {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background: var(--ant-primary-color);

    &.active {
      height: 2px;
    }

    &.edit {
      background: repeating-linear-gradient(90deg, rgba(23, 23, 23, 0.5), rgba(23, 23, 23, 0.5) 5px, transparent 5px, transparent 10px);

      &.active {
        background: repeating-linear-gradient(90deg, var(--primaryColor), var(--primaryColor) 5px, transparent 5px, transparent 10px);
      }
    }
  }
}