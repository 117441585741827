.returnRow {
  display: inline-flex;
  align-items: center;
  width: 100%;

  .returnArrow {
    margin: 8px 8px 16px;

    &:hover {
      cursor: pointer;
    }
  }

  .paymentMethodName {
    margin: 0;
    width: 100%;
  }
}
