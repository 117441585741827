.checkboxGroup {
  margin-top: 15px;

  :global(.ant-checkbox-group-item) {
    display: block;
  }
}

.checkableTag {
  border-radius: 13px !important;
  font-size: 12px;
  width: 26px;
  height: 26px;
  padding: 3px 0 !important;
  text-align: center;
}

.submitItem {
  text-align: center;
  margin-top: 40px;
  button {
    border-radius: 6px;
  }
}

.drawerForm {
  margin-bottom: 24px;
  :global(.ant-form-item) {
    margin-bottom: 8px;
    :global(.ant-form-item-label) {
      padding: 4px;
      label {
        font-size: 12px;
        color: grey;
      }
    }
  }
}

.list {
  :global(.ant-list-item) {
    position: relative;
    background-color: white;
    box-shadow: 1px 1px 4px #d0d0d0;
    border-radius: 2px;
    padding: 8px 12px;
    margin-bottom: 8px;
    transition: 0.3s;
    //color: #868686;
    cursor: pointer;
    height: 48px;
    .date {
      display: inline-block;
      color: lightgrey;
    }
    &:hover {
      box-shadow: 1px 1px 4px #969696;
    }
  }
}

.subHeader {
  margin-bottom: 8px;
  display: inline-flex;
  gap: 8px;
}

.linkButton {
  border-color: var(--ant-primary-color);
  border-radius: 6px;
  margin-top: 24px;
}