.filterTag {
  padding: 4px 4px 4px 10px;
  border-radius: 14px;
  margin: 0;
  cursor: pointer;
  background-color: var(--primaryColor);
  color: var(--primaryColorContrast);
  :global(.ant-tag) {
    margin: 0;
    opacity: 0.7;
  }
  .removeFilter {
    opacity: 0.5;
    font-size: 18px;
    display: block;
  }
  img {
    height: 18px;
    margin-top: -2px;
  }

  .imageTag {
    padding-left: 0;
    img {
      height: 16px;
      margin: -1px 4px 0px 2px;
      border-radius: 10px;
    }
  }

  &.disabled {
    background: repeating-linear-gradient(45deg, #d6d6d6, #d6d6d6 10px, #e7e7e7 10px, #e7e7e7 20px);
    color: #929292;
  }
}
