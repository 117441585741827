.root {
  display: inline-flex;
  align-items: center;
  padding: 30px;
  width: 100%;
  justify-content: center;

  .icon {
    font-size: 16px;
    margin-left: 22px;
  }
}
