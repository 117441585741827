.root {
  grid-column-end: -3;
  border: none;
  width: 100%;
  position: relative;

  .addSplitterButton {
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background: var(--ant-primary-color);

    &.active {
      height: 2px;
    }

    &.edit {
      background: repeating-linear-gradient(90deg, rgba(23, 23, 23, 0.5), rgba(23, 23, 23, 0.5) 5px, transparent 5px, transparent 10px);

      &.active {
        background: repeating-linear-gradient(90deg, var(--primaryColor), var(--primaryColor) 5px, transparent 5px, transparent 10px);
      }
    }
  }

  .addIcon {
    position: absolute;
    padding: 17px 4px;
    margin: 0 7px;
    font-size: 24px;
    color: rgba(23, 23, 23, .5);
    background-color: transparent;
    box-sizing: content-box;
    z-index: 999;

    &.open,
      &.active {
      color: var(--primaryColor);
    }

    :global(svg) {
      background-color: white;
    }
  }
}

.dropdown {
  border-radius: 3px;
  border: 1px solid var(--primaryColor);
  width: 328px;
  background-color: white;
  padding: 8px 0;
  overflow-y: auto;
  max-height: 80vh;

  .title {
    color: #707070;
    padding: 4px 8px 8px 8px;
  }

  .typeRow {
    display: flex;
    align-items: center;
    padding: 4px 8px;

    &:hover {
      cursor: pointer;
      background-color: var(--primaryColor);

      .icon {
        border-color: #171717;
      }

      .type {
        color: var(--primaryColorContrast);
      }
    }

    .icon {
      background-color: #ffffff;
      color: rgba(23, 23, 23, .5);
      font-size: 24px;
      padding: 12px;
      border: 1px solid rgba(23, 23, 23, .3);
      border-radius: 3px;
    }

    .type {
      padding: 8px;
      font-size: 15px;
    }
  }
}