.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .upload {
    width: 100%;

    :global(.ant-upload.ant-upload-select) {
      display: block;
    }
  }

  .textArea {
    height: 170px;
  }

  .isError {
    color: #B40505;
  }
}