.root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgb(240, 242, 245);
  user-select: none;
  position: relative;

  .gridRoot {
    min-height: 100%;

    .selected {
      box-shadow: 0px 0px 12px var(--primaryColor);
    }
  }
}

.tempWidget {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.siderPageWithFilters {

  &.loading {
    cursor: wait;

    * {
      pointer-events: none;
    }
  }
}

.layoutHeader {
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.24) 0 7px 4px -7px;
  padding: 0 16px;
  height: auto;

  .filterTagsContainer {
    display: flex;
    > span {
      margin-right: 8px;
    }
    > :global(.ant-space) {
      display: inline-flex;
      padding: 18px 0;
      line-height: 20px;
    }
  }
}

.contentContainer {
  max-height: 100%;
  position: relative;
  overflow: hidden;
}