.listItem {
  position: relative;
  background-color: white;
  box-shadow: 1px 1px 4px #d0d0d0;
  border-radius: 2px;
  padding: 8px 12px;
  margin-bottom: 8px;
  transition: 0.3s;
  cursor: pointer;
  height: 48px;
  .date {
    display: inline-block;
    opacity: 1;
    color: lightgrey;
  }
  .editBtn {
    display: none;
    opacity: 0;
    color: #7c7c7c;
  }
  &:hover {
    box-shadow: 1px 1px 4px #969696;
    > .date {
      display: none;
      opacity: 0;
    }
    > .editBtn {
      display: inline-flex;
      opacity: 1;
    }
  }
  &.active {
    background-color: var(--primaryColor);
    color: var(--primaryColorContrast);
    > .editBtn button {
      color: var(--primaryColorContrast);
    }
  }
  .flexContent {
    display: inline-flex;
    gap: 8px;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
  }
}
