

.root {
  .separatorLabel {
    padding: 12px 0;
  }

  .list {
    max-height: 40vh;
    overflow-y: auto;

    .wrong {
      color: #B40505;
    }
  }

  .errorText {
    color: #B40505;
  }
}