.root {
  background-color: white;
  padding: 8px 12px;
  position: relative;
  height: 100%;
  width: 100%;

  .chartdiv {
    height: calc(100% - 24px);
    width: 100%;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat-Medium', 'Montserrat-SemiBold', 'Montserrat-Regular', 'Roboto', 'Helvetica Neue', 'Arial',
    'sans-serif';
}

.modal {
  top: 2.5vh;
  .fullscreenChartdiv {
    width: 95%;
    height: 90vh;
  }
}

.container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 24px);
}

.financialAnalysisModal {
  :global(.ant-modal-header) {
    border: none;
  }
}

.notepad {
  margin-top: 12px;
  padding: 0;
  resize: none;
  &:focus {
    background-color: whitesmoke;
  }
  &:disabled {
    color: inherit;
  }
}
