.root {
  bottom: 0;
  left: 0;
  width: auto !important;

  // :global(.ant-modal-title) {
  //   &:hover {
  //     cursor: move;
  //   }
  // }

  .tabRoot {
    overflow: visible;

    :global(.ant-tabs-tab-btn) {
      color: var(--primaryColor);
    }

    :global(.ant-tabs-ink-bar) {
      background: var(--primaryColor);
    }
  }

  .iconContainer {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;

    .iconUploader {
      margin-left: 2.5rem;

      :global(.ant-upload.ant-upload-select-picture-card) {
        width: 80px;
        height: 80px;
      }
    }
  }

  :global(.avatar-uploader > .ant-upload) {
    width: 128px;
    height: 128px;
  }

  :global(.ant-modal-body) {
    padding: 24px 24px 0;
  }
}

:global(.ant-modal-wrap) {
  pointer-events: none;
  overflow: hidden;
}
