.amountInput {
  :global(.ant-input-number-input) {
    text-align: right;
  }
  :global(.ant-input-number-handler-wrap) {
    display: none;
  }
}
.maxAmountSpan {
  font-size: 9px;
  position: absolute;
  right: 10px;
  bottom: -1px;
}

// ---------------------------------------------------------------------------------------------------------------------

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-self: end;
  justify-content: space-evenly;
  width: 100%;
}

.actionButton:not(.disabled):hover {
  cursor: pointer;
  transform: scale(1.2);
}

.actionButton {
  transition: transform 0.2s;
  border: none;
  height: 100%;
  padding: 0.5rem;
  text-align: center;
}

.actionButtonIcon {
  font-size: 50px;
}

.buttonCaption {
  font-family: Montserrat-Semibold;
  font-size: 11px;
  margin-top: -10px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modalContent {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #20272b;
}

.modalIcon {
  font-size: 110px;
}

.modalTitle {
  line-height: 30px;
  text-align: center;
}

.titleBold {
  font-family: Montserrat-SemiBold;
  text-transform: uppercase;
}

.hr {
  background-color: #707070;
  height: 1px;
  width: 100%;
  margin: 20px 0;
}

.modalButton {
  border: 1px solid #707070;
  font-family: Montserrat-SemiBold;
  transition: 0.5s;
  border-radius: 3px;
  font-size: 20px;
  padding: 25px 18px;
  line-height: 0;
  margin: 0 4px;
}

.modalButton:hover {
  border: 1px solid #707070;
  background: var(--primaryColor);
  color: white;
}

.inputRow {
  display: flex;
}

.inputLabel {
  white-space: nowrap;
}

.input {
  margin-left: 1rem;
  min-width: 150px;
  font-size: 20px;
  font-family: Montserrat-SemiBold;
}

.inputCurrency {
  position: absolute;
  right: 80px;
}

.inputSubtitle {
  font-size: 12px;
  color: #20272b;
  font-family: Montserrat-SemiBold;
  margin-top: 2px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@-webkit-keyframes spinning {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(spinning) {
  0% {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.spinning {
  -webkit-animation: spinning 2s linear infinite;
  -moz-animation: spinning 2s linear infinite;
  -ms-animation: spinning 2s linear infinite;
  -o-animation: spinning 2s linear infinite;
  animation: spinning 2s linear infinite;
}

.buttonsContainer2 {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
  padding-right: 24px;
}

.actionDiv {
  width: 60px;
  font-size: 13px;
  text-align: center;

  .actionButton2 {
    border: none;
    color: black;
    background: none;
    font-size: 14px;

    &:hover {
      background: none;
      color: black;

      &:not([disabled]) {
        transform: scale(1.2);
      }
    }
  }

  .actionIcon {
    font-size: 24px;
    margin: 0 2px;
  }

  &:hover {
    .actionLabel {
      opacity: 1;
    }
  }

  .actionLabel {
    opacity: 0;
    position: absolute;
    width: 60px;
    font-size: 13px;
    line-height: 13px;
    color: grey;
    bottom: 0;
  }
}

.customUnderline {
  border-bottom: 2px solid #242424;
}

.orderDetailTitle {
  margin: auto 0.5rem;
  font-size: 18px;
  font-family: Montserrat-SemiBold;
}

.backButton {
  font-size: 16px;
  padding: 24px;
  color: #242424;
  transition: background-color 0.3s linear;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
