.container {
  width: 22px;
  height: 22px;
  left: 0;
  top: 0;
  position: absolute;
}

.container:after {
  content: '';
  width: 0;
  height: 0;
  border-radius: 2px 0;
  border-style: solid;
  border-width: 24px 24px 0 0;
  border-color: var(--primaryColor) transparent transparent transparent;
  left: 0;
  top: 0;
  position: absolute;
}

.pinIcon {
  position: absolute;
  top: 2px;
  left: 2px;
  color: white;
  font-size: 11px;
  z-index: 1;
}