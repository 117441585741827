.root {
  width: 100%;
  margin-top: 6px;
  position: relative;
  min-height: 67px;
  transition: opacity 0.5s, transform 0.5s;
  transform: translate(0%);
  opacity: 1;
  z-index: 2;

  &.oob {
    display: none;
  }

  &.isColumnActive {
  }

  .title {
    position: absolute;
    z-index: 99;
    top: -6px;
    left: 14px;
    line-height: 13px;
    font-size: 11px;
    background-color: white;
    padding: 0 4px;
  }

  .inputContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;

    &.locked {
      border: 1px solid var(--primaryColor);
      border-radius: 4px;
      padding-left: 11px;
    }

    .selectRoot {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px solid var(--primaryColor);
      overflow: hidden;
      position: absolute;

      &.skeletonContainer {
        display: inline-flex;
        align-items: center;
      }

      &.error {
        border: 2px solid red;
      }

      :global(.ant-select-selector) {
        height: 100% !important;
        border: none !important;

        :global(.ant-select-selection-item),
        :global(.ant-select-selection-search),
        :global(.ant-select-selection-placeholder) {
          display: flex;
          align-items: center;
        }
      }
    }

    .iconsContainer {
      position: absolute;
      top: 20px;
      right: 20px;
      display: inline-flex;
    }
  }

  &.isMovingLeft {
    transition: all 0s !important;
    transform: translate(-100%);
  }

  &.isMovingRight {
    transition: all 0s !important;
    transform: translate(100%);
  }

  &.isAppearing {
    opacity: 0;
  }

  &:not(.isColumnActive) {
    opacity: 0.32;
  }
}
