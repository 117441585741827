
.tagCell {
  padding: 3px 8px;
  border-radius: 4px;
  position: relative;
  > :global(.anticon) {
    font-size: 14px;
    vertical-align: sub;
  }
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
    border-radius: 4px;
  }
}