.root {
  width: 100%;
  background-color: whitesmoke;
}

.body {
  overflow: scroll;
  > div {
    padding: 24px;
    margin: auto;
    max-width: 1240px;
    width: 100%;
  }
}
