.root {
  transition: all 0.2s;
  display: inline-flex;
  position: fixed;
  z-index: 10;
  background-color: white;
  box-shadow: 2px 1px 2px 1px #eaeaea;
  padding: 0;
}

.trigger {
  padding: 3px 16px 0 24px;
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    background-color: whitesmoke;
  }
}
