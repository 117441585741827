.root {
  position: absolute;
  display: inline-flex;
  top: 0;
  right: 4px;

  .lockedIcon,
  .copyIcon {
    &.white {
      * {
        stroke: white !important;
      }
    }
  }

  .warningIcon {
    svg > g > g {
      path {
        stroke: #ffba00 !important;
        fill: #ffba00 !important;
      }
      &:nth-last-child(1) path {
        stroke: white !important;
        fill: white !important;
      }
    }
  }

  .validIcon {
    svg > g > g {
      path {
        stroke: #227703 !important;
        fill: #227703 !important;
      }
      &:nth-last-child(1) path {
        stroke: white !important;
        fill: white !important;
      }
    }
  }
}

:global(.elem):hover {
  .lockedIcon,
  .warningIcon,
  .copyIcon {
    * {
      stroke: white !important;
    }
  }
}
