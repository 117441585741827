.flex {
  display: flex;
}

.inputName {
  font-weight: bold;
  margin-bottom: 8px;
  border: 1px solid transparent !important;
  &:hover,
  &:focus {
    border-color: var(--primaryColor) !important;
  }
}

.deleteButton {
  display: block;
  margin: 24px auto;
  border-color: var(--negative) !important;
  background-color: white !important;
  color: var(--negative) !important;
  font-weight: bold;
  border-radius: 4px !important;
  padding: 8px 20px;
  height: auto;

  &:hover {
    background-color: var(--negative) !important;
    color: white !important;
    border-color: var(--negative) !important;
  }
}
