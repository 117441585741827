.deleteButton {
  display: block;
  margin: 24px auto;
  border-color: var(--negative) !important;
  background-color: white !important;
  color: var(--negative) !important;
  font-weight: bold;
  border-radius: 4px !important;
  padding: 8px 20px;
  height: auto;

  &:hover {
    background-color: var(--negative) !important;
    color: white !important;
    border-color: var(--negative) !important;
  }
}

.drawerForm {
  margin-bottom: 24px;
  :global(.ant-form-item) {
    margin-bottom: 8px;
    :global(.ant-form-item-label) {
      padding: 4px;
      label {
        font-size: 12px;
        color: grey;
      }
    }
  }
}