.blockContent {
  text-align: center;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.infoText {
  padding: 12px 4px;
}