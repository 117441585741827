.root {
  margin: 12px 0;
}

.img {
  max-width: 100%;
  max-height: 100%;
  background: repeating-linear-gradient(45deg, #d6d6d6, #d6d6d6 10px, #e7e7e7 10px, #e7e7e7 20px);
}

.upload {
  & > div {
    height: 60px !important;
    width: 60px !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
  }
  .btnContainer {
    position: absolute;
    opacity: 0;
    transition: all 0.5s;
  }
  &:hover .btnContainer {
    opacity: 1;
  }
}

.info {
  font-size: 11px;
  color: grey;
}
