.numberIcon {
  background-color: var(--primaryColor);
  color: white;
  font-size: 15px;
  min-width: 17px;
  height: 18px;
  border-radius: 4px;
  text-align: center;
  line-height: 17px;
  font-family: Montserrat-SemiBold;
  margin: 4px 0;
}
