.root {
  padding: 12px;
  color: #20272b;

  box-shadow: 1px 0px 4px #a3a3a3;
  background-color: white;
  user-select: none;
  height: 100%;

  .title {
    font-size: 21px;
    font-weight: bold;
  }
}
