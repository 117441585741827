.root {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  .message {
    display: block;
  }
}
