.root {
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 11px;

  .select {
    width: 100%;
  }

  .addButton {
    font-size: 20px;
    padding: 5px;
  }
}
