.root {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;

  .buttons :global(.ant-btn) {
    text-transform: uppercase;
  }
}
