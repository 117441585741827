.row {
  position: relative;
  margin: 8px 0;
  width: 100%;
  overflow: hidden;
  padding-right: 12px;
  text-overflow: ellipsis;

  .rowLabel {
    font-size: 13px;
    color: #6d7172;
  }

  .rowNull {
    color: #6d7172;
  }

  .rowValue {
    font-size: 15px;
  }

  .img {
    height: 23px;
    border-radius: 2px;
  }

  &:hover {
    .copyIcon {
      opacity: 1;
    }
  }

  .copyIcon {
    opacity: 0;
    color: var(--primaryColor);
    font-size: 16px;
    position: absolute;
    top: 12px;
    right: 0;
  }

  .checkCopyIcon {
    color: var(--primaryColor);
    font-size: 16px;
    position: absolute;
    top: 12px;
    right: 0;
  }
}
