.picker {
  & > div {
    box-shadow: none !important;
  }
}

.trigger {
  width: 40px;
  height: 26px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
  &.disabled {
    cursor: not-allowed;
  }
}

.copyableLabel {
  margin: auto !important;
  color: #a0a0a0 !important;
}

.description {
  margin: -8px 0 8px 68px;
  color: grey;
  font-size: 11px;
}
