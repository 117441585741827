.container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 24px);
}

.containerForbidden {
  background-color: #ffffff;
  display: flex;
  height: calc(100% - 24px);
}