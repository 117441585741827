.inputContainer {
  display: flex;

  .input {
    width: 100%;
  }
}
.addButton {
  font-size: 20px;
  padding: 5px;
}
