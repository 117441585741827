.flex {
  display: flex;
}

.inputName {
  font-weight: bold;
  margin-bottom: 8px;
  border: 1px solid transparent !important;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: var(--primaryColor) !important;
  }
}

.deleteButton {
  margin-left: 8px;
  color: #b5b5b5 !important;
}

.seeAll {
  cursor: pointer;
  font-size: 9px;
  color: #b5b5b5;
  text-align: right;
  &:hover {
    font-weight: 900;
    color: #7c7c7c;
  }
}
