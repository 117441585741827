.animation {
  animation-iteration-count: infinite;
  width: 60px;

  &.rotate {
    animation-name: rotate;
    animation-duration: 3s;
  }

  &.bounce {
    animation-name: bounce;
    animation-duration: 1.5s;
  }

  &.heartbeat {
    animation-name: heartbeat;
    animation-duration: 1.5s;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(720deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  20% {
    transform: translateY(-4px);
  }

  40% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(-4px);
  }

  80% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.gifStaticPreview {
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  height: 60px;
  width: 60px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  > span {
    font-size: large;
    color: grey;
  }
}
