.root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: stretch;

  .contentContainer {
    flex: 1;
    transition: width 2s;

    .content {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }

  .siderContainer {
    overflow-x: visible;
    position: relative;
    box-shadow: 0 3px 5px 1px #eaeaea;
    background-color: white;

    .button {
      position: absolute;
      left: -30px;
      top: 36px;
      background-color: #f5f5f5;

      &.buttonOpen {
        transform: scale(-1);
        &:hover {
          transform: scale(-1.5);
        }
      }

      &:hover {
        transform: scale(1.5);
        left: -40px;
        top: 40px;
      }
    }

    .sider {
      height: 100%;
      width: 0;
      position: relative;
      transition: width 0.2s ease-in-out;

      &.open {
        width: 350px;
        overflow-y: auto;
      }
    }
  }
}
