.optionalInputContainer {
  display: flex;
  justifyContent: space-between;

  .optionalInput {
    flex: 1;
  }

  .removeButton {
    margin-top: 29px;
    color: #868686;
  }
}