.root {
  .bottomButtons {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;

    button {
      margin-bottom: 12px;
    }
  }

  .validMessage {
    margin-bottom: 12px;
    text-align: center;
    color: var(--ant-primary-color);
  }

  .rowList {
    &:global(.ant-row) {
      margin: 24px 0;
    }
  }

  .infoText {
    text-align: center;
    margin-bottom: 48px;
    font-size: 12px;
  }

  .listItem {
    &:global(.ant-list-item) {
      position: relative;
      //cursor: pointer;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 0 5px 0 #eaeaea;
      margin-bottom: 8px;
      padding: 12px 8px;

      &.active {
        background-color: var(--primaryColor);

        :global(.ant-list-item-meta-title) {
          color: var(--primaryColorContrast);
        }

        :global(.ant-list-item-meta-description) {
          color: var(--primaryColorContrast);
        }

        :global(.ant-tag) {
          background-color: var(--primaryColor);
          color: var(--primaryColorContrast);
        }

        :global(.anticon) {
          color: var(--primaryColorContrast) !important;
        }
      }

      :global(.ant-avatar) {
        background-color: var(--primaryColor);
      }

      :global(.ant-list-item-extra) {
        margin-left: 8px;
      }

      &:focus {
        box-shadow: 0 3px 5px 1px #eaeaea;
      }
    }
    .dragIcon {
      color: #b5b5b5;
      font-size: 22px;
    }
    .listIndexIndicator {
      background-color: var(--primaryColor);
      color: white;
      font-size: 13px;
      border-radius: 4px;
      text-align: center;
    }

    .certificate {
      max-height: 96px;
      overflow-x: hidden;
      overflow-y: auto;
      word-break: break-all;
    }
  }

  .editButton {
    margin: 8px 0;
    height: 72px;
    &:global(.ant-btn) {
      text-align: left;
      background-color: var(--secondaryColor) !important;
      color: var(--secondaryColorContrast) !important;
    }
  }

  .siderForm {
    margin-bottom: 24px;
    padding: 6px 20px;
    :global(.ant-form-item) {
      margin-bottom: 8px;
      :global(.ant-form-item-label) {
        padding: 4px 0;
        label {
          font-size: 12px;
          color: grey;
        }
      }
    }
  }
  .noMerchantPage:hover {
    cursor: not-allowed;
  }

  .noMerchantPage {
    opacity: 0.5;
    pointer-events: none;
  }

  .noMerchantMessage {
    text-align: center;
    color: #b40505;
    padding-top: 0.5rem;
  }

  .loader {
    text-align: center;
    padding: 100px 0;
  }

  .drawerForm {
    margin-bottom: 24px;
    :global(.ant-form-item) {
      margin-bottom: 8px;
      :global(.ant-form-item-label) {
        padding: 4px;
        label {
          font-size: 12px;
          color: grey;
        }
      }
    }
    .searchInput {
      margin-bottom: 48px;
      :global(.ant-input-wrapper) {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        :global(.ant-input-group-addon) {
          border-radius: 2px;
          background-color: var(--primaryColor);
          color: var(--primaryColorContrast);
        }
        :global(.ant-input-affix-wrapper) {
          border: none;
          border-radius: 2px;
        }
      }
    }
    .tableRow {
      height: 57px;
    }
  }

  .removeConditionBtn {
    position: absolute;
    right: 32px;
    top: -48px;
  }

  .warningSwitch {
    background-color: orange;
  }

  .warningText {
    font-size: 12px;
    color: orange;
  }

  .bottomButtons {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
  }

  :global(label) {
    width: 100%;
  }

  .labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .copyIcon {
      transition: transform 0.2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}