.filterTagsContainer {
  position: initial;
  display: flex;
  > span {
    margin-right: 8px;
  }
  > div.collapsable {
    flex-grow: 1;
    display: inline-flex;
    margin: 16px 0;
    line-height: 32px;
    overflow: auto;
    max-height: calc(100vh - 120px);
    &.collapsed {
      max-height: 32px;
    }
  }
  > :global(.ant-tag) {
    height: 28px;
    margin: auto 0;
    padding: 0 10px;
    font-size: 28px;
  }
  > .collapseButton {
    position: absolute;
    left: calc(50% - 16px);
    bottom: -16px;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 18px;
    padding: 0;
    color: #7c7c7c;
    &:hover {
      color: var(--primaryColor);
    }
    > span {
      transition: transform 0.4s;
    }
    &.collapsed > span {
      transform: rotate(180deg);
    }
  }
}

.trashIcon {
  color: #707070;
  font-size: 20px;
  vertical-align: middle;
  margin: 0 8px;
  cursor: pointer;
}
