.modalContent {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #20272b;

  .modalTitle {
    padding: 24px 0;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-self: end;
    margin-left: auto;

    .modalButton {
      //   border: 1px solid #707070;
      //   font-family: Montserrat-SemiBold;
      //   transition: .5s;
      //   border-radius: 3px;
      //   font-size: 20px;
      //   padding: 25px 18px;
      //   line-height: 0;
      margin: 0 4px;

      //   &:hover {
      //     border: 1px solid #707070;
      //     background: var(--primaryColor);
      //     color: white;
      //   }
    }
  }
}
