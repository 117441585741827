.root {
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 24px 12px;

  .nameContainer {
    display: inline-flex;
    align-items: center;

    .icon {
      margin-bottom: .5em;
      padding: 8px;
      font-size: 16px;
    }
  }

  .subtitle {
    display: block;
  }

  .titleFooter {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}