.noAccess {
  text-align: center;
  margin-top: 200px;
}

.root {
  :global(.ant-form-item-label) {
    padding: 0;
    label {
      font-size: 14px;
      font-family: 'Montserrat-Regular', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
    }
  }
  :global(.ant-form-item) {
    margin-bottom: 12px;
    :global(.ant-form-item-label > label) {
      color: #868686;
      width: 100%;
      padding-right: 42px;
      gap: 4px;
      &:not(:global(.ant-form-item-required-mark-optional)) {
        justify-content: flex-end;
        flex-direction: row-reverse;
      }
    }
  }

  :global(.ant-btn.ant-btn-primary[type='submit']) {
    height: 38px;
    line-height: 30px;
    border-radius: 6px;
  }

  :global(.ant-drawer-header) {
    flex-flow: row-reverse;
    :global(.ant-drawer-header-title) {
      flex-flow: row-reverse;
      margin-left: 12px;
      :global(.ant-drawer-close) {
        margin-right: 0;
      }
    }
  }
}

.pageHeader {
  padding: 12px 24px;
  z-index: 200;
  border-bottom: 1px solid whitesmoke;
  background-color: white;
  :global(.ant-page-header-back-button) {
    font-size: 20px;
  }
}

.underline {
  border-bottom: 2px solid;
}

.headerButton {
  font-size: 12px;
  :global(.anticon) {
    font-size: 18px;
  }

  &.active {
    color: var(--primaryColor);
  }
}
