.root {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 2px;
  margin-bottom: 8px;
  background-color: #ffffff;
  box-shadow:
    0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
    0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);

  &.loading {
    height: 32px;
    background-color: #e7e7e7;
  }

  &.error {
    height: 32px;
    background-color: #e7e7e7;
    font-size: 11px;
    > span {
      margin: 12px;
      font-size: 16px;
    }
  }

  &.disabled {
    background: repeating-linear-gradient(45deg, #d6d6d6, #d6d6d6 10px, #e7e7e7 10px, #e7e7e7 20px);
    color: #929292;
  }

  &.clickable {
    cursor: pointer;
  }

  &.draggable {
    cursor: grab;
  }

  &.rounded {
    border-radius: 2px 2px 2px 12px;
  }

  .avatar {
    height: 32px;
    border-radius: 2px 0 0 2px;
  }

  &.rounded > .avatar {
    border-radius: 2px 0 0 12px;
  }

  .iconContainer {
    font-size: 20px;
    padding: 1px 6px 0;
    color: #ffffff;
    background-color: #4e4e4e;
    border-radius: 2px 0 0 2px;
  }

  &.rounded > .iconContainer {
    border-radius: 2px 0 0 12px;
  }

  .label {
    flex-grow: 1;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .labelInput {
      padding-left: 4px;
      line-height: 22px;
      height: 22px;
      &:focus,
      &:hover {
        background-color: whitesmoke;
      }
    }
  }

  .hoverIcon {
    margin-right: 12px;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
  }
  &:hover {
    .hoverIcon {
      opacity: 1;
      transition: none;
    }
  }

  .handleIcon {
    margin: 4px 4px 0 0;
    color: #b5b5b5;
  }

  .removeButton {
    position: absolute;
    right: -16px;
    cursor: pointer;

    .removeIcon {
      font-size: 12px;
      color: #b5b5b5;
    }
  }
}

.separator {
  width: 100%;
  border-top: dashed #b5b5b5 1px;
  margin-bottom: 8px;
}

.slotIndex {
  position: absolute;
  left: 8px;
  font-size: 9px;
  color: #b5b5b5;
}
