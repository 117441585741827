.root {
  &.isLoading {
    filter: blur(2px);
    pointer-events: none;
  }

  .checkboxContainer {
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px;

    &.partialCheckboxContainer {
      .warningIcon {
        line-height: 24px;

        svg > g > g {
          path {
            stroke: #ffba00 !important;
            fill: #ffba00 !important;
          }
          &:nth-last-child(1) path {
            stroke: white !important;
            fill: white !important;
          }
        }
      }
    }

    :global(.ant-checkbox-wrapper) {
      display: flex;
      align-items: center;
      margin: 0;

      :global(.ant-checkbox) {
        top: 0;
      }
    }

    :global(.ant-checkbox-inner) {
      width: 24px;
      height: 24px;

      &::after {
        width: 8px;
        height: 13px;
      }
    }

    :global(.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner),
    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: var(--positive) !important;
      border-color: var(--positive) !important;
    }

    :global(.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after) {
      border-color: #ffffff;
    }
    :global(.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: grey !important;
      border-color: grey !important;
    }
  }
}
