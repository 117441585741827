.root {
  &.clickable:hover {
    cursor: pointer;
  }

  .lottie {
    margin: auto;
  }

  .colored {
    svg {
      path {
        stroke: inherit;
        fill: inherit;
      }
    }
  }
}
