//.table {
//  :global(.ant-table table) {
//    border-spacing: 0 6px;
//    background-color: #979797;
//  }
//  :global(.ant-table-thead > tr > th) {
//    background-color: #979797;
//    border-bottom: none;
//  }
//
//  &.tableReconciled {
//    :global(.ant-table) {
//      background: transparent;
//    }
//    :global(.ant-table table) {
//      background-color: #fafafa;
//    }
//    :global(.ant-table-thead > tr > th) {
//      background-color: #fafafa;
//    }
//  }
//
//  :global(.ant-table-tbody > tr > td:first-child) {
//    border-radius: 6px 0 0 6px;
//  }
//  :global(.ant-table-tbody > tr > td:last-child) {
//    border-radius: 0 6px 6px 0;
//  }
//
//  :global(.ant-table-footer) {
//    background: transparent;
//  }
//}
//
//.tableRow {
//  background-color: white;
//  border-radius: 6px;
//}
//
//.tableRowShadow {
//  box-shadow: 0 1px 4px 0 rgba(23, 23, 23, 0.35);
//  border-radius: 6px;
//}

.tagCell {
  padding: 3px 8px;
  border-radius: 4px;
  position: relative;
  > :global(.anticon) {
    font-size: 14px;
    vertical-align: sub;
  }
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
    border-radius: 4px;
  }
}

.button {
  border-radius: 6px;
  border: none;
  width: 240px;
  height: 40px;
  color: grey;
  //box-shadow: 0 1px 4px 0 rgba(23, 23, 23, 0.35);
  box-shadow: none;

  &.action {
    background-color: #7f7f7f;
    color: #ffffff;
  }
}

.descriptions :global(.ant-descriptions-item) {
  padding-bottom: 4px;
  :global(.ant-descriptions-item-container) {
    height: 28px;
  }
  :global(.ant-descriptions-item-label),
  :global(.ant-descriptions-item-content) {
    overflow: hidden;
    align-items: center;
    font-size: 12px;
  }
  :global(.ant-typography-copy) {
    opacity: 0;
  }
  &:hover {
    :global(.ant-typography-copy) {
      opacity: 1;
    }
  }
  &:nth-child(2) {
    border-left: 3px solid white;
    padding-left: 12px;
  }
}

// Matcher v3
.table {
  width: 100%;
  table {
    border-spacing: 0 4px;
    background-color: #eaeaea;
  }
  :global(.ant-table-footer) {
    background-color: #eaeaea;
  }
}

.row {
  overflow: hidden;
  border: none;
  background-color: white;
  > td:first-child {
    border-radius: 8px 0 0 8px;
    padding: 0 !important;
    color: white;
    text-align: center;
    font-size: 24px;
  }
  > td:last-child {
    border-radius: 0 8px 8px 0;
  }
  > td {
    border: none !important;
  }
}
.selectedRow {
  cursor: auto;
  background-color: var(--ant-primary-color-active-deprecated-d-02);
  &:hover > td {
    background-color: inherit !important;
  }
}
.unselectedRow {
  cursor: pointer;
  &:hover > td {
    background-color: var(--ant-primary-color-hover) !important;
  }
}
.reconciledRow {
  > td:first-child {
    background-color: #66c476 !important;
  }
}
.pendingRow {
  > td:first-child {
    background-color: #fa9500 !important;
  }
}
.unreconciledRow {
  > td:first-child {
    background-color: #ff4f4e !important;
  }
}
.candidateRow {
  cursor: pointer;
  &:hover > td {
    background-color: var(--ant-primary-color-active-deprecated-d-02) !important;
  }
  > td:first-child {
    color: var(--ant-primary-color);
    background-color: var(--ant-primary-color-active-deprecated-d-02) !important;
  }
}
