.navigationContainer {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  padding: 1rem;

  &.isLoading {
    pointer-events: none;
    opacity: 0.5;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, 0);
  }
}
