.elem:not(.noHover):hover {
  cursor: pointer;
}

.elem.oob {
  display: none;
}

.elem.isLoading {
  filter: blur(2px);
}

.elem {
  border: 1px solid #707070 !important;
  border-radius: 4px !important;
  display: flex;
  transition: opacity 0.5s, transform 0.5s;
  transform: translate(0%);
  opacity: 1;
  overflow: hidden;
  position: relative;
  padding: 8px;

  &.locked:hover {
    cursor: not-allowed;
  }

  &:not(.noHover):hover,
  &.active {
    color: var(--primaryColorContrast);
    background-color: var(--primaryColor);
    .contentContainer {
      color: var(--primaryColorContrast);
    }
  }

  .contentContainer {
    min-height: 33px;
    line-height: 33px;
    color: #8b8b8b;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .contentContainer.loading {
    filter: blur(2px);
    pointer-events: none;
  }

  .contentColumn {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .titleContainer {
    min-height: 22px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tooltip {
    white-space: nowrap;
    max-width: none;
  }

  &.isMovingLeft {
    transition: all 0s !important;
    transform: translate(-100%);
  }

  &.isMovingRight {
    transition: all 0s !important;
    transform: translate(100%);
  }

  &.isAppearing {
    opacity: 0;
  }

  &:not(.isColumnActive) {
    opacity: 0.32;
  }

  .dragNDrop {
    position: absolute;
    right: 7px;
    top: 28px;
    width: 12px;
  }
}
