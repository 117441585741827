.root {
  display: flex;
  position: relative;
  line-height: 18px;
  height: 58px;
  z-index: 999;
  background-color: #F5F5F5;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  width: 100%;

  &.splitter {
    background-color: #7c7c7c;

    .icon {
      color: #F5F5F5;
    }
  }

  &.active {
    transform: scale(1.05);
  }

  &.parentActive,
  &.active {
    box-shadow: 0 3px 6px var(--primaryColor);

    .icon {
      color: var(--primaryColor)
    }

  }


  .img {
    padding: 13px 4px;
  }

  .icon {
    font-size: 24px;
    padding: 17px 8px;
    color: rgba(23, 23, 23, .5);
  }

  .text {
    flex-grow: 1;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    .textContent {
      overflow-x: hidden;
    }
  }

  .title {
    flex: 1;
    font-size: 14px;
    color: #20272B;
    overflow-x: hidden;
    overflow-y: visible;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .type {
    flex: 1;
    font-size: 10px;
    color: rgba(23, 23, 23, .5);
    text-transform: uppercase;
    overflow-x: hidden;
    overflow-y: visible;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}