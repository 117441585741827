.root {
  position: sticky;
  top: 30px;
  z-index: 3;
  margin-bottom: 80px;
  margin-top: 30px;
  box-shadow: 0px 0px 24px 19px white;

  .breadcrumb {
    grid-row: 1;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    height: 4px;
    border-radius: 4px;
    background-color: var(--primaryColor);

    &.overflow {
      background: linear-gradient(
        to right,
        var(--primaryColor),
        var(--primaryColor) 10%,
        white 10%,
        white 12.5%,
        var(--primaryColor) 12.5%,
        var(--primaryColor) 15%,
        white 15%,
        white 17.5%,
        var(--primaryColor) 17.5%,
        var(--primaryColor) 20%,
        white 20%,
        white 22.5%,
        var(--primaryColor) 22.5%,
        var(--primaryColor) 25%,
        white 25%,
        white 27.5%,
        var(--primaryColor) 27.5%
      );
    }

    .step {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .stepDot {
        width: 10px;
        height: 10px;
        background-color: var(--primaryColor);
        border-radius: 16px;
        transition: all 0.3s;

        &.first {
          width: 16px;
          height: 16px;
        }

        &.isActive {
          width: 15px;
          height: 15px;
          background-color: white;
          border: 2px solid var(--primaryColor);
        }
      }

      &.hoverable:hover {
        cursor: pointer;

        .stepDot {
          transform: scale(1.5);
        }
      }
    }
  }
}
