.root {
  :global(label) {
    width: 100%;
  }

  .labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .copyIcon {
      transition: transform 0.2s;

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}
