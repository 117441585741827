.splitter {
  position: relative;

  .contentContainer {
    width: 100%;

    &.active {
      transform: scale(1.1);
    }
  }
}