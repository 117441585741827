.avatar {
  background-color: var(--primaryColor);
}

.rowList {
  &:global(.ant-row) {
    margin-bottom: 24px;
  }
}

.infoText {
  text-align: center;
  margin-bottom: 48px;
  font-size: 12px;
}

.searchInput {
  margin-bottom: 48px;
  :global(.ant-input-wrapper) {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    :global(.ant-input-group-addon) {
      border-radius: 2px;
      background-color: var(--primaryColor);
      color: var(--primaryColorContrast);
    }
    :global(.ant-input-affix-wrapper) {
      border: none;
      border-radius: 2px;
    }
  }
}

.listItem {
  &:global(.ant-list-item) {
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 #eaeaea;
    margin-bottom: 8px;
    padding: 12px 8px;
    :global(.ant-list-item-meta-title) {
      overflow: hidden;
      margin-right: 8px;
      text-overflow: ellipsis;
    }
    &.active {
      background-color: var(--primaryColor);
      :global(.ant-list-item-meta-title) {
        color: var(--primaryColorContrast);
      }
      :global(.ant-list-item-meta-description) {
        color: var(--primaryColorContrast);
      }
      :global(.ant-tag) {
        background-color: var(--primaryColor);
        color: var(--primaryColorContrast);
      }
      :global(.anticon) {
        color: var(--primaryColorContrast) !important;
      }
    }
    :global(.ant-avatar) {
      background-color: var(--primaryColor);
    }
    &:focus {
      box-shadow: 0 3px 5px 1px #eaeaea;
    }
  }
}

.createButton {
  margin: 8px 0;
  height: 72px;
  &:global(.ant-btn) {
    text-align: left;
    background-color: var(--secondaryColor) !important;
    color: var(--secondaryColorContrast) !important;
  }
}
