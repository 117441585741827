@font-face {
  font-family: 'Montserrat-Regular';
  src:
    local('Montserrat'),
    url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src:
    local('Montserrat'),
    url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src:
    local('Montserrat'),
    url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@function mixw($variable, $opacity) {
  @return unquote(
    'linear-gradient(to top,rgba(255, 255, 255,' + $opacity + '),rgba(255, 255, 255,' + $opacity + ')) ' + $variable
  );
}

#app {
  --antd-wave-shadow-color: var(--primaryColor);
  //color: rgba(0, 0, 0, 0.65);
  font-family: 'Montserrat-Medium', 'Montserrat-SemiBold', 'Montserrat-Regular', 'Roboto', 'Helvetica Neue', 'Arial',
    'sans-serif';
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--primaryColor) lightgrey;

  &::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 5px;
  }
}

.ant-menu.ant-menu-dark {
  background-color: var(--secondaryColor);
  color: var(--secondaryColorContrast);

  .ant-menu-item {
    color: var(--secondaryColorContrast);
    background-color: var(--secondaryColor);
  }
  .ant-menu-item-selected,
  .ant-menu-item-active {
    color: var(--primaryColorContrast);
    background-color: var(--primaryColor);
  }
  .ant-menu-sub .ant-menu-item-selected,
  .ant-menu-sub .ant-menu-item-active {
    color: var(--primaryColorContrast);
    background-color: var(--primaryColor);
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right-width: 7px;
  }
  .ant-menu-item-group-title,
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-arrow {
    color: inherit;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: auto;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
  .ant-menu-sub.ant-menu-inline {
    background: var(--secondaryColor);
  }
}
.ant-menu-submenu {
  .ant-menu-item:not(:last-child),
  .ant-menu-item {
    margin: 0;
  }
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px);
}
.ant-tag {
  border: none;
  border-radius: 10px;
}

.ant-timeline-item:last-child {
  .ant-timeline-item-tail {
    display: none;
  }
}
.ant-timeline-item-head {
  background-color: whitesmoke;
}
.ant-timeline-item-tail {
  border-color: #c7c6c6;
}
.ant-timeline-item-content {
  margin-left: 46px !important;
}

.ant-empty-normal {
  min-height: 80px !important;
  padding-top: 8px !important;
}

// full customization of antd Anchor (see MerchantAccountView.js)
.norbr-anchor.ant-anchor-wrapper {
  overflow: visible !important;
  position: absolute;
  .ant-anchor {
    .ant-anchor-ink {
      display: none;
    }
    .ant-anchor-link {
      overflow: visible;
      padding: 4px 0;
      margin-left: -13px;
      &:hover {
        .ant-anchor-link-title {
          width: auto;
          span:nth-child(2) {
            display: inline-block;
          }
        }
      }
      .ant-anchor-link-title {
        background-color: white;
        box-shadow: 1px 0 4px #a3a3a3;
        padding: 1px 5px;
        border-radius: 16px;
        width: 24px;
        overflow: hidden;
        text-overflow: clip;
        span:nth-child(2) {
          display: none;
          margin-left: 4px;
        }
      }
    }
  }
}

// custom ant form for tile form
.tile-form.ant-form-vertical {
  .ant-form-item {
    margin: 8px 0 !important;
  }
  .ant-form-item-label {
    padding: 0 !important;
    label {
      font-size: 13px !important;
      color: #6d7172 !important;
    }
  }
}

// editable cell in table
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

// search input (admin lists)
.norbr-search-input {
  .ant-input-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 16px;
    .ant-input-group-addon {
      border-radius: 16px;
      background-color: var(--primaryColor);
      color: var(--primaryColorContrast);
    }
    .ant-input-affix-wrapper {
      border: none;
      border-radius: 16px;
    }
  }
}

// breadcrumb (admin views)
.norbr-admin-breadcrumb.ant-breadcrumb {
  font-size: 11px;
}

// table deleted-row (partner routes validation)
.ant-table-tbody > tr.ant-table-row.deleted-row {
  background-color: #ff8989;
  &:hover > td {
    background-color: #ff8989;
  }
}

// form item reversed label: control > control label (use with Switch or Checkbox Form.Item)
.norbr-reverse-form-item {
  flex-direction: row-reverse !important;
  & > .ant-form-item-label {
    flex-grow: 1 !important;
    text-align: left;
    margin-left: 8px;
  }
  & > .ant-form-item-control {
    flex: 0 !important;
  }
}

.ant-layout-sider {
  background-color: var(--secondaryColor);
}
.ant-layout-header {
  background-color: white;
}

.ant-picker-preset {
  width: 33%;
  text-align: center;
  font-size: 13px;
}

// Segmented elems use primary and secondary
.ant-segmented-item-selected {
  background-color: var(--primaryColor);
  color: var(--primaryColorContrast);
}
.ant-segmented-thumb {
  background-color: var(--primaryColor);
}

//.ant-btn {
//  border-radius: 6px;
//}

// amcharts
//.amcharts-amexport-menu {
//  top: 0;
//  left: 0;
//}
//.amcharts-amexport-item {
//  border: 2px solid #777;
//}
//
//.amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
//  top: -3px !important;
//}
