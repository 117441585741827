.checkboxContainer {
  :global(.ant-checkbox-wrapper) {
    align-items: center;
    margin-bottom: 8px;
    margin-left: 0;
    white-space: nowrap;

    :global(.ant-checkbox) {
      top: 0;
    }
  }

  :global(.ant-form-item-control-input-content) {
    display: flex;
    flex-direction: column;
  }

  :global(.ant-checkbox-inner) {
    width: 24px;
    height: 24px;

    &::after {
      width: 8px;
      height: 13px;
    }
  }

  :global(.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner),
  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: var(--positive);
    border-color: var(--positive);
  }

  :global(.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after) {
    border-color: #ffffff;
  }
  :global(.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: grey !important;
    border-color: grey !important;
  }

  .loading {
    filter: blur(2px);
  }
}

.warningIcon {
  svg > g > g {
    path {
      stroke: #ffba00 !important;
      fill: #ffba00 !important;
    }
    &:nth-last-child(1) path {
      stroke: white !important;
      fill: white !important;
    }
  }
}