.KpisBlock {
  background: none;
}

.rowTop {
  padding: 0;
  margin: 0 0 5px 0 !important;
}

.filtersBlock {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.kpiContainer {
  height: 100%;
  background-color: #fff;
  padding: 15px 24px 15px 0;
  max-height: 97px;

  * {
    pointer-events: none;
  }
}

.kpi_turnover {
  font-weight: 800;
  font-size: 24px;
  padding-top: 0px;
}

.kpi_orders {
  font-size: 13px;
  margin-bottom: 0px;
  min-height: 29px;
}

.kpi_variation {
  font-size: 13px;
  margin-top: -3px;
}

//---------------------------------------------------------------

.forceNoRadius {
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
}

.page-dashboard {
  overflow-y: auto;
  height: 100%;
  margin-top: 0em;
  padding-top: 1.2em !important;
  background-color: rgb(240, 242, 245);
}

//-----------------------------

.brainpowerSelectDate {
  float: right;
  margin-bottom: 10px;
}

.page-dashboard .ant-calendar-picker-input {
  height: 25px;
  font-size: 0.85em;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
}

.ant-calendar-range {
  min-width: 600px;
}

.force-mtb {
  margin-top: 10px;
  margin-bottom: 10px;
}

.selectYourPeriodLabel {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.btnDatePeriod {
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  font-size: 12px;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: var(--primaryColor);
  border: 0px solid;
}

.ant-calendar-date:hover {
  background: var(--primaryColor);
  cursor: pointer;
}

.ant-calendar-range {
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  top: 35px;
}

.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: rgba(0, 168, 168, 0.3);
  border: 0;
  border-radius: 0;
  content: '';
}

//-----------------------------

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: rgba(0, 168, 168, 0.3);
  font-weight: 500;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: rgba(0, 168, 168, 0.3);
  font-weight: 500;
}

.ant-select-dropdown.ant-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 12px;
  padding: 2px 10px;
  font-weight: 400;
  font-size: 0.8em;
  color: #20272b;
  line-height: 20px;
}
.ant-select-dropdown {
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
}

.ant-select-dropdown li {
  font-weight: 500;
}

.customForm .ant-select-selection,
.customForm .ant-input {
  border-radius: 0px 0px 0px 0px !important;
  -moz-border-radius: 0px 0px 0px 0px !important;
  -webkit-border-radius: 0px 0px 0px 0px !important;
}

.ant-select-selection:active {
  border: 0px solid #d9d9d9;
  box-shadow: 0 0 0 1px rgba(36, 36, 36, 0.2);
}

.ant-select-selection:hover {
  border: 1px solid #d9d9d9;
}

//----------

.chartTitle {
  min-height: 36px;
  font-size: 17px;
  color: #666666;
  font-weight: 500;
}

//.amcharts-amexport-item {
//  border-radius: 0px 0px 0px 0px !important;
//  -moz-border-radius: 0px 0px 0px 0px !important;
//  -webkit-border-radius: 0px 0px 0px 0px !important;
//  background: rgba(0, 168, 168, 0.3) !important;
//  font-size: 11px;
//}
//
//.amcharts-amexport-item-level-0 {
//  font-size: 13px;
//  font-weight: 500;
//}
//
//.amcharts-amexport-item-level-1 {
//  font-size: 11px;
//  font-weight: 500;
//}
//
//.amcharts-amexport-item-level-2 {
//  font-size: 11px;
//  font-weight: normal;
//}

//------------

.box {
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
}

.chartBlock {
  margin-bottom: 30px;
}

.no-pr {
  padding-right: 0 !important;
}

.no-pl {
  padding-left: 0 !important;
}

.emptyData {
  background: #fff;
}

.ant-advanced-search-form {
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
}

.ant-select-selection {
  min-height: 32px;
  font-size: 0.9em;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 11px;
}

//.ant-form label {
//  font-size: 11px;
//}
//
//.ant-form-item-label {
//  display: inline-block;
//}

.forcemt {
  margin-top: 5px;
}

.ant-select-dropdown-menu-item-group-title {
  background: #242424;
  color: #fff;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
}

.btn-toggle {
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border: 0px solid #000000;
  height: 25px;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  padding: 0 15px;
  border: 0px;
  background: rgba(0, 0, 0, 0.15) !important;
}

.ant-empty-normal {
  margin-top: 0;
  margin-bottom: 0;
  min-height: 350px;
  padding-top: 130px;
}

#globalPerformanceChart .ampopup-content {
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.05);
  width: 90%;
}

.fullScreenChartIcon:hover,
.fullScreenExitChartIcon:hover {
  cursor: pointer;
  transform: scale(1.5);
}

.fullScreenExitChartIcon {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.chartModal {
  top: 2.5vh;
}

.chartHeader {
  display: inline-flex;
  justify-content: space-between;
}

.chartHeaders {
  display: inline-flex;
  justify-content: space-between;
}

.chartHeader {
  padding: 5px;
}

.react-grid-item.react-grid-placeholder {
  background: grey !important;
  border: dashed #000000 3px !important;
}
