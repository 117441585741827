.searchInput {
  > input:focus,
  :hover {
    box-shadow: none !important;
  }
  > span {
    color: #b5b5b5;
  }
}

.searchResults {
  margin: 8px;
}

.subtitle {
  font-size: 9px;
  color: #b5b5b5;
  margin-bottom: 2px;
}

.seeAll {
  cursor: pointer;
  font-size: 9px;
  color: #b5b5b5;
  text-align: right;
  &:hover {
    font-weight: 900;
    color: #7c7c7c;
  }
}
