.datePickerPopup {
  // hide first panel for DateRangePicker
  :global(.ant-picker-panels > *:first-child button.ant-picker-header-next-btn) {
    visibility: visible !important;
  }
  :global(.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn) {
    visibility: visible !important;
  }
  :global(.ant-picker-panels > *:last-child) {
    display: none;
  }
  :global(.ant-picker-panel-container, .ant-picker-footer) {
    width: 280px !important;
  }
  :global(.ant-picker-footer-extra > div) {
    flex-wrap: wrap !important;
  }
  // hide first panel for DateRangePicker

  .panel {
    width: min-content;
    padding: 12px;

    > div {
      margin-bottom: 8px;
      .lightgrey {
        color: lightgrey;
      }
    }

    :global(.ant-picker-input) {
      width: 164px;
    }

    .periodsMenu {
      border: none;
      height: 270px;
      overflow-y: auto;

      li {
        height: 32px;
        line-height: 32px;
        padding: 0 8px;
        border-radius: 2px;
        margin-bottom: 4px;

        .periodDescription {
          display: inline-block;
          line-height: 16px;
          font-size: 11px;
          font-style: italic;
          text-align: right;
        }
      }
    }

    .absolutePanel {
      text-align: center;
    }

    :global(.ant-tabs-tab-active .ant-tag) {
      background-color: var(--primaryColor);
      color: var(--primaryColorContrast);
    }
  }

  .timePicker {
    width: 110px;
    :global(.ant-picker-panel > .ant-picker-time-panel) {
      width: min-content;
    }

    :global(.ant-picker-panel-container, .ant-picker-footer) {
      width: auto !important;
    }
  }
}

.inputGroup {
  // DATE_RANGE
  > :global(.ant-picker-range) {
    width: calc(100% - 31px);
  }
  // ASYNC_LIST, LIST, TEXT
  > :global(.ant-select) {
    width: calc(100% - 130px);
  }
  // AMOUNT, NUMBER
  > :global(.ant-input-group) {
    width: calc(100% - 130px);
    > :global(.ant-form-item) {
      width: 40%;
      margin-bottom: 0;
      :global(.ant-input-number) {
        width: 100%;
      }
      &:nth-child(3) {
        width: 20%;
        padding: 0 4px;
      }
    }
  }
  // BOOLEAN
  > div.booleanInput {
    width: calc(100% - 31px);
    display: inline-block;
  }

  > :last-child {
    color: #868686;
  }
}
