.calendarIcon {
  font-size: 60px;
  color: #55C985;
}

.title {
  font-family: 'Montserrat-SemiBold';
  font-size: 16px;
}

.content {
  background-color: #F5F4F4;
  padding: 16px;

  .card {
    padding: 12px 8px;
    border-radius: 6px;
    display: block;

    &.green {
      background-color: #55C985;
      color: white;
    }

    &.orange {
      background-color: #FA9502;
      color: white;
    }

    &.grey {
      background-color: #7F7F7F;
      color: white;
    }

    .cardTitle {
      min-height: 44px;
    }
  }
}

.infoIcon {
  font-size: 24px;
  color: #7F7F7F;
}

.titleLoading {
  font-family: 'Montserrat-SemiBold';
  font-size: 23px;
}

.checkCircleIcon {
  font-size: 175px;
  color: #55C985;
}