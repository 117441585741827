.steps {
  :global(.ant-steps-item-title) {
    font-family: Montserrat-SemiBold;
  }
}

.loader {
  margin: 20px auto;
  display: block;
}

.uploadContainer {
  margin: 20px 35px 20px 0;
}
