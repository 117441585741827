.root {
  padding: 6px 24px;
}

.tile {
  max-width: 400px;
  margin: auto;
}

.indexIcon {
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  border-style: double;
  border-width: 6px;
  color: white;
  background-color: grey;
  text-align: center;

  span {
    line-height: 21px;
    font-size: 12px;
  }
}
